
/* eslint-disable @typescript-eslint/camelcase */
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import i18n from '@/i18n';
import { AtomBaseInputRef } from '@/components/atoms/AtomInput/AtomBaseInput.vue';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import AtomPasswordInput from '@/components/atoms/AtomInput/AtomPasswordInput.vue';
import AtomTextInput from '@/components/atoms/AtomInput/AtomTextInput.vue';
import AtomRouterLink from '@/components/atoms/AtomRouterLink.vue';
import AtomSetup from '@/components/atoms/AtomSetup.vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import { toast, ToastVariants } from '@/components/atoms/AtomToast';
import AtomText from '@/components/atoms/AtomText.vue';
import MoleculeModal from '@/components/molecules/modal/MoleculeModal.vue';
import OrganismPageSubHeader from '@/components/organisms/page-header/OrganismPageSubHeader.vue';
import RouteNamesEnum from '@/router/RouteNamesEnum';

import OrganismPayPalSetup, { MODAL_ID as PayPalModalId } from '@/components/organisms/payment/OrganismPayPalSetup.vue';

import PaymentTypesEnum from '@/enums/payment/PaymentTypesEnum';
import FeaturesEnum from '@/enums/settings/FeatureEnum';
import { PASSWORD_REGEX } from '@/common/config';
import { changePassword } from '@twogo/cidaas/src/lib/CidaasService';

import { hasFeature } from '@/services/feature/FeatureService';
import {
  getBrandingById,
  injectBrandingIntoDocument,
} from '@/services/branding/BrandingService';

import { assignSiteByToken, deleteUser } from '@/api/user/userApi';

export default Vue.extend({
  name: 'TemplateSettingsSecurity',
  components: {
    AtomButton,
    AtomSetup,
    AtomSvgIcon,
    AtomText,
    AtomPasswordInput,
    AtomTextInput,
    AtomRouterLink,
    MoleculeModal,
    OrganismPageSubHeader,
    OrganismPayPalSetup,
  },
  data: () => ({
    FeaturesEnum,
    PaymentTypesEnum,
    PayPalModalId,
    RouteNamesEnum,
    ButtonVariant,
    siteToken: '',
    isTokenValid: true,
    loadingAssignToOrg: false,
    loadingChangePass: false,
    currentPassword: null as string|null,
    newPassword: null as string|null,
    newPasswordConfirm: null as string|null,
    isNewPasswordValid: false,
    isNewPasswordConfirmValid: false,
    passwordRequirements: PASSWORD_REGEX.map((regex, key) => ({
      validator: (input) => regex.test(input || ''),
      showOnValid: false,
      description: i18n.t(`register.passwordRequirements.option-${key}`).toString(),
    })),
    deleteAccountPass: '',
    deleteAccLoading: false,
  }),
  computed: {
    ...mapGetters('user', [
      'getUserId',
      'getIdentityId',
      'getAccessToken',
      'features',
      'hasPaypalEmail',
      'getPaypalEmail',
      'email',
      'hasPaymentTypeEnabled',
      'b2cFree',
    ]),
  },
  methods: {
    ...mapActions('user', ['fetchUser']),
    hasFeature,
    validateNewPasswordVsConfirm(val: string) {
      return val === this.newPassword;
    },
    validateNewPasswordVsOld() {
      return this.currentPassword !== this.newPassword;
    },
    validateInputs() {
      const {
        newPasswordRef,
        newPasswordConfirmRef,
      } = this.$refs as {newPasswordRef: AtomBaseInputRef; newPasswordConfirmRef: AtomBaseInputRef};

      if (this.newPassword !== null && newPasswordRef) newPasswordRef.validateInput();
      if (this.newPasswordConfirm !== null && newPasswordConfirmRef) newPasswordConfirmRef.validateInput();
    },
    setNewPassword(val: string) {
      this.newPassword = val;
      this.validateInputs();
    },
    setNewPasswordConfirm(val: string) {
      this.newPasswordConfirm = val;
      this.validateInputs();
    },
    setOldPassword(val: string) {
      this.currentPassword = val;
      this.validateInputs();
    },
    async changePassword(): Promise<void> {
      this.loadingChangePass = true;
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      const { isError, response } = await changePassword({
        old_password: this.currentPassword || '',
        new_password: this.newPassword || '',
        confirm_password: this.newPasswordConfirm || '',
        identityId: this.getIdentityId,
      }, this.getAccessToken);

      if (isError) {
        toast(
          this.$bvToast,
          this.$t('settings.securityPage.passwordChangeModal.messages.wrongPassword').toString(),
          ToastVariants.DANGER,
        );
        return;
      }
      this.loadingChangePass = false;
      if (response.changed) {
        window.localStorage.removeItem('access_token');
        this.$router.push({
          params: { event: 'passwordChanged', passwordChanged: 'true' },
          path: `/${this.$i18n.locale}/login?passwordChanged=true`,
        });
      }
    },
    cancelResetPassword(): void {
      this.$bvModal.hide('settings-change-password');
    },
    resetChangePassword() {
      this.currentPassword = null;
      this.newPassword = null;
      this.newPasswordConfirm = null;
      this.isNewPasswordValid = false;
      this.isNewPasswordConfirmValid = false;
    },
    async assignUserToSite() {
      this.loadingAssignToOrg = true;

      const { getUserId: userId, siteToken } = this;
      const { status, data: user } = await assignSiteByToken(userId, siteToken);

      if ((status === 200 || status === 201) && !!user) {
        await this.fetchUser(userId);
        const brand = getBrandingById(user.site.companyId.toString());

        if (brand) injectBrandingIntoDocument(brand);

        toast(
          this.$bvToast,
          this.$t(
            'settings.account.organisation.messages.success',
            { name: user.site.companyName },
          ).toString(),
          ToastVariants.SUCCESS,
        );
        this.$bvModal.hide('assing-to-organisation');
      }
      if (status === 400) {
        this.isTokenValid = false;
        toast(
          this.$bvToast,
          this.$t('settings.account.organisation.messages.error').toString(),
          ToastVariants.DANGER,
        );
      }

      this.loadingAssignToOrg = false;
    },
    async deleteAccount() {
      this.deleteAccLoading = true;
      const { status } = await deleteUser(this.getUserId);

      if (status === 200) {
        toast(
          this.$bvToast,
          this.$t('settings.account.deleteAccount.messages.success').toString(),
          ToastVariants.SUCCESS,
        );
        setTimeout(() => {
          window.location.href = `https://www.twogo.com/${this.$i18n.locale}`;
        }, 1500);
      } else {
        toast(
          this.$bvToast,
          this.$t('settings.account.deleteAccount.messages.error').toString(),
          ToastVariants.DANGER,
        );
      }
      this.$bvModal.hide('delete-account');
      this.deleteAccLoading = false;
    },
  },
});
